import React from 'react';
import { NavLink } from 'react-router-dom';


const Navigation = () => {
    return (
        <div className='navigation'>
            <NavLink to={"/"}>
                PAN'PLUME GRAPHISTE FREELANCE
            </NavLink>
            <NavLink to={"Work"}>
                WORK
            </NavLink>
            <NavLink to={"About"}>
                A PROPOS
            </NavLink>
        </div>
    );
};

export default Navigation;