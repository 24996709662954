import React from 'react';

const Exo = () => {
    return (
        <div style={{ textAlign: "center", background: "#00B300" }}>
            <h1>CECI EST UN EXERCICE</h1>
        </div>
    );
};

export default Exo;