import React from 'react';

const TitrePage = (props) => {
    return (
        <div className='titrepage'>
            <span className={props.bigstyle}>
                {props.whitetitle}
            </span>
            <br></br>
            <span className={props.littlestyle}>
                {props.yellowtitle}
            </span>
        </div>
    );
};

export default TitrePage;