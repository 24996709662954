import React from 'react';
import Musique from './Musique';

const CompoMusique = (props) => {
    return (
        <div className='compoMusique'>
            <div className='compoMusique_text'>
                <span>{props.span}</span>

                <h2>{props.h2}</h2>
                <br></br>
                <Musique />
            </div>
        </div>
    );
};

export default CompoMusique;