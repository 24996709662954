import React from 'react';

const Musique = () => {
    return (
        <div>
            <iframe className='musique' title="Spotify Player" data-src="" scrolling="no" src="https://embed.spotify.com/?uri=spotify%3Atrack%3A0F3fscqwFqD52Tpbep5hBW&amp;color=black&amp;style=list"></iframe>
        </div>
    );
};

export default Musique;