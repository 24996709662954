import React from 'react';

const Grid = () => {
    return (
        <div className='grid'>
            <div className='image'></div>
            <div className='image'></div>
            <div className='image'></div>
            <div className='image'></div>
            <div className='image'></div>
            <div className='image'></div>
            <div className='image'></div>
        </div>
    );
};

export default Grid;