import React from 'react';

const Header = () => {
    return (
        <div className='header'>
            <div>
                <span>Graphiste Freelance | Lyon</span>
            </div>
            <div className='Titre'>
                <span>PAN'PLUME</span>
            </div>
        </div>
    );
};

export default Header;