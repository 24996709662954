import React from 'react';
import Accueil from '../components/Accueil';
import Blockwhite from '../components/Blockwhite';
import Communication from '../components/Communication';
import CompoMusique from '../components/CompoMusique';
import Footer from '../components/Footer';
import Image from '../components/Image';
import TextButtons from '../components/TextButtons';


const Home = () => {
    return (
        <div className='home'>
            <Accueil />
            <div className='divflex'>
                <Image propstyle="imageMenu" />
                <TextButtons h2="AU MENU" span="DECOUVREZ MES DERNIERES REALISATIONS" button="PORTFOLIO" />
            </div>
            <Communication span="BESOIN D'UNE" h2="COMMUNICATION CAFEINEE ?" />
            <div className='divflex'>
                <TextButtons h2="VOS CREAS" span="Des idées dynamiques et pleine de pep'spour tous vos supports de communication.
                Apportez de l'énergie à votre image !" button="ENERGISER" />
                <Image propstyle="imagecreas" />
            </div>

            <CompoMusique span="PAN'PLUME & MORE" h2="AU SON DE VOTRE MUSIQUE" />
            <div className='divflex'>
                <Image propstyle="imagenuancier" />
                <TextButtons h2="UN NUANCIER DE COULEURS" span="Une palette de prestations,
un savoir-faire pluriel,
une couleur unique
à vos créations." button="COLORER" />

            </div>
            <Footer />
        </div>
    );
};

export default Home;