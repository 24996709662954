import React from 'react';
import Buttons from './Buttons';

const TextButtons = (props) => {
    return (
        <div className='textButtons'>
            <div className='text_Buttons_div'>
                <h2>{props.h2}</h2>
                <br></br>
                <span>{props.span}</span>
                <br></br>
                <Buttons NameButton={props.button} />
            </div>
        </div>
    );
};

export default TextButtons;