import React from 'react';
import Exo from '../components/Exo';
import Navigation from '../components/Navigation';

const About = () => {
    return (
        <div>
        </div>
    );
};

export default About;