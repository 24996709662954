import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer_adresse'>
                <h3>Adresse</h3>
                <ul>
                    <li>XX XX</li>
                    <li>Rue du Dev</li>
                    <li>Ile de France</li>
                    <li> Tèl: 07 xx xx xx xx xx</li>
                </ul>
            </div>
            <div className='footer_contact'>
                <h3>CONTACT</h3>
                <ul>
                    <li>xxxx@gmail.com</li>
                    <li>Tèl: 07 xx xx xx xx xx</li>
                </ul>
            </div>
            <div className='footer_hours'>
                <h3>HOURS</h3>
                <span>OPEN 24/24</span>
            </div>
        </div>
    );
};

export default Footer;