import React from 'react';

const Accueil = () => {
    return (
        <div className='Accueil'>
            <div className='KeepCalm'>
                <span>KEEP CALM</span><br></br>
                <span>AND CALL</span><br></br>
                <span>A GRAPHIC</span><br></br>
                <span>DESIGNER</span><br></br>
            </div>
        </div>

    );
};

export default Accueil;