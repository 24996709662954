import React from 'react';
import Footer from '../components/Footer';
import Grid from '../components/Grid';
import Image from '../components/Image';
import TitrePage from '../components/TitrePage';


const Work = () => {
    return (
        <div className='work'>
            <TitrePage whitetitle="WORK" bigstyle="bigstyle" yellowtitle="Since 2008" littlestyle="littlestyle" />
            <Image propstyle="imagework" />
            <Grid />
            <Footer />


        </div>
    );
};

export default Work;